* {
  box-sizing: border-box;
}

:root {
  --bg-color: #201f1f;
  --font-color: #f4f4f4;
  --main-color: #464775;
  --board-color: #3a3b56;
  --item-color: #252423;
}

body {
  background: var(--bg-color);
  color: var(--font-color);
  font-family: -apple-system, BlinkMacSystemFont, “Roboto”, “Droid Sans”,
    “Helvetica Neue”, Helvetica, Arial, sans-serif;
}

aside {
  text-align: center;
  color: #333;
  line-height: 1.7;
}

.main-content {
  max-width: 1000px;
  width: 100%;
  margin: 4em auto 0;
  overflow: hidden;
  border-radius: 3px;
}

.title {
  background: var(--main-color);
  color: #fff;
  padding: 30px 10px;
  grid-column: span 5;
  text-align: center;
  font-size: 72px;
}

.card {
  background: var(--board-color);
  padding: 10px;
  display: grid;
  grid-gap: 3px;
  grid-template-rows: repeat(5, 110px);
  grid-template-columns: repeat(5, 1fr);
  text-transform: uppercase;
}

.item {
  background: var(--item-color);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.35;
  user-select: none;
}

.item {
  padding: 15px;
}

.checkmark {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.clear-button {
  margin: 2em 0 0;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid;
  border-radius: 3px;
  color: var(--main-color);
  background-color: var(--board-color);
  color: var(--font-color);
  padding: 8px 10px;
}

.clear-button:hover {
  color: var(--main-color);
  background-color: var(--font-color);
}

.bingo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.bingo-overlay span {
  padding: 5px;
  color: var(--main-color);
  position: relative;
  font-size: 150px;
  top: 30%;
  animation: glow 1s ease infinite;
}

@keyframes glow {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@media only screen and (max-width: 600px) {
  .main-content,
  body {
    margin: 0;
  }

  .item {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 10px;
  }

  .card {
    padding-left: 0;
    padding-right: 0;
  }

  .bingo-overlay span {
    font-size: 100px;
  }
}
